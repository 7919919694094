import { React, useEffect } from 'react';
import { Headliner } from "../components/Headliner";
import { Footer } from "../components/Footer";
import "../styles/AboutUs.css";
import { story } from "../list/aboutUs";

export function AboutUs() {

    useEffect(() => {
        function checkVisibility() {
          var elements = document.querySelectorAll('.fade-in');
          for (var i = 0; i < elements.length; i++) {
            var windowHeight = window.innerHeight;
            var scrollY = window.scrollY || window.pageYOffset;
            var elementOffsetTop = elements[i].getBoundingClientRect().top + scrollY;
    
            if (scrollY + windowHeight > elementOffsetTop) {
              elements[i].classList.add('show');
            }
          }
        }
    
        window.addEventListener('scroll', checkVisibility);
        window.addEventListener('resize', checkVisibility);
    
        checkVisibility();

        return () => {
          window.removeEventListener('scroll', checkVisibility);
          window.removeEventListener('resize', checkVisibility);
        };
    }, []);

    const dynamicTItle = "B.F. company story and work";

    return(
        <>
        <div className="page_body">
            <div className="headliner"><Headliner title={dynamicTItle}/></div>
            <div className='story_display'>
                {story.map((item,index) =>{
                    return(
                        <>
                        <div className='regular'>
                          <div className='story_background'>
                            {item.storyBackground ? (
                                <img className='s_b_image' src={item.storyBackground} alt='background'/>
                            ) : (
                                <img className='s_b_image hidden' src="#" alt='background'/>
                            )}</div>
                          <div key={index} className="story_box fade-in">
                              <div className="text_story_box">{item.text}</div>
                              <div className="image_story_box"><img className="img_story" src={item.story_img} alt="story" /></div>
                          </div>
                        </div>
                        </>
                    );
                })}
            </div>
            <Footer />
        </div>
        </>
    );
    
}