import { useEffect } from 'react';
import { products } from '../list/products';
import '../styles/ProductsDisplay.css';

export function ProductsDisplay(){

    useEffect(() => {
        function checkVisibility() {
          var elements = document.querySelectorAll('.fade-in_prod');
          for (var i = 0; i < elements.length; i++) {
            var windowHeight = window.innerHeight;
            var scrollY = window.scrollY || window.pageYOffset;
            var elementOffsetTop = elements[i].getBoundingClientRect().top + scrollY;
    
            if (scrollY + windowHeight > elementOffsetTop) {
              elements[i].classList.add('show_prod');
            }
          }
        }
    
        window.addEventListener('scroll', checkVisibility);
        window.addEventListener('resize', checkVisibility);
    
        checkVisibility();

        return () => {
          window.removeEventListener('scroll', checkVisibility);
          window.removeEventListener('resize', checkVisibility);
        };
    }, []);

    return(
        <>
        <div className="product_box">
            {products.map((item,index) => {
                return(<>
                <div key={index} className="product">
                    <div className="prod_description">{item.description}</div>
                    <div className='typologies'>
                        {item.subjects.map((subjectItem,subjectIndex) => {
                            return(<>
                                <div key={subjectIndex} className={subjectItem.subject}>
                                    <div className='first_half'>
                                        <div className='subject'>{subjectItem.subject}</div>
                                        <div className='desc_box'>{subjectItem.desc.map((desc, descIndex) => (
                                            <div key={descIndex} className={desc.cName}>{desc.text}</div>
                                        ))}</div>
                                    </div>
                                    <div className='second_half fade-in_prod'>
                                        <div className='gradient_effect'></div>
                                        {subjectItem.brands.map((brandItem,brandIndex) => {
                                            return(
                                                <>
                                                <div className={`${subjectItem.subject.includes('laser') ? 'laser' : 'machines'}_photo_box`} key={brandIndex}>
                                                    <img className='brand' src={brandItem} alt={brandItem}/>
                                                </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>
                            </>)
                        })}
                    </div>
                </div>
                </>)
            })}
        </div>
        </>
    );
}