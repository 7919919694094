import first_bf from '../images/francesco biagi.jpg';
import parts from '../images/pila di pezzi.JPG';
import laser from '../images/laser/laser marker open.jpg'
import shipments from '../images/shipments.png';


export const story = [
    {
        storyBackground: "",
        text:(<div className='center'><div className='margin bolder'>B.F was founded in 1969 by Biagi Francesco whose initials give the company its name.</div>
            <br/>
            <div className='margin'>B.F. was founded in the city of Bologna, Italy, and together with the city of Modena, is the city home of tractors and engineering companies.
            <br/>
            The company site changed once from the foundation, and moved from near the city center, to more outside the city in the town of Zola Perdosa.</div></div>),
        story_img: first_bf
    },
    {
        storyBackground: "",
        text:(<div className='center'><div className='margin bolder'>We are focused in producing spare parts for heavy machinery.</div>
        <br/>
        <div className='margin'>Our parts extends for many major havy machinery brands as Caterpillar, John Deere, Komatsu, Case, Hitachi, New Holland, BobCat.
        <br/>
        About transportation we provide the manufacturing of parts for the major trucks brands: Volvo, Mercedes, Hyunday.</div></div>),
        story_img: parts
    },
    {
        storyBackground: "",
        text:(<div className='center'><div className='margin bolder'>Laser markers and laser welders are used and sold by us</div>
        <br/>
        <div className='margin'>We use laser marking machines to engrave clean and high resolution codes on our spare parts, and with the laser welder we have an efficent and precise machine for the purpose.
        <br/>
        We provide our costumers with the same efficient equipment.</div></div>),
        story_img: laser
    },
    {
        storyBackground: "",
        text:(<div className='center'><div className='margin bolder'>Shipments all over the world</div>
        <br/>
        <div className='margin'>We ship to our costumers all over the world, from U.S.A to Asia and middle East.</div></div>),
        story_img: shipments
    }
]